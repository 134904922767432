import React from 'react'
import { graphql } from 'gatsby'
import { Button, CardInquire } from '@roar/components'
import { mapper } from '../common/utils'
import LinkWrapper from './LinkWrapper'
import RichTextRenderer from '../components/RichTextRenderer'

const HEADER_PROPS_MAP = {
  headline: ['headline'],
  description: ['description'],
}

const LINK_MAP = {
  links: ['links'],
}

const CardInquireWrapper = (props) => {
  const linkProps = mapper(props, LINK_MAP)

  const { headline, description, cta, ...rest } = mapper(props, HEADER_PROPS_MAP)

  return (
    <CardInquire
      headline={headline}
      description={
        <div style={{ width: '100%' }}>
          <RichTextRenderer content={description} color={undefined} />
        </div>
      }
      renderCta={() => <LinkWrapper {...linkProps} colorScheme="red" />}
      {...rest}
    />
  )
}
export default CardInquireWrapper

export const cardInquire = graphql`
  fragment CardInquire on ContentfulInquiryCard {
    id
    name
    headline
    contentful_id
    description {
      raw
    }
    links {
      ...LinkFields
    }
  }
`
